import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/modal/Modal.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/modal" storybook="https://workday.github.io/canvas-kit/?path=/story/components-popups-modal--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19822-16098&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/af5d622df14742870ec748d008c79beb/b5a09/modal-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.48648648648648%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+ElEQVR42oWSX2vTUBjG97m8UIaI0iQ2obFrknYr6qUgm8OCN34FL6RN2m5ZmyK06xAFv4OId96rhdm5gdhmqe3yp4/nPc1C3FYXeM5JTs77O8/zclY0TQMpr2t4sJaHLMuJFEW5VlRT0BcM0goNhqbjcXYNladbaLVbsG2bq9FoLFW9Xoe9u4sXz55jXcnD0PUFsMCGsl7CyxsqDswWEAGu6+LPZIL5fI5lTxiGfH7ndLF5S8GGUULh3GHJKKJ8V0HXdjAbneL7YIDhcJgUEfiigiDg/w76fTxZVVBOA41iEffui+h0OnzT8ckJJsxhFEUcelFph30GlAQBRcZIekgfWUFKgL7vI2Sw6Ar5zBk5TAOFNJBsbrDIW6s57Fs2cBbi93iM6XSKKC7kIrcEjfu6FJg4FCW8YQ5p/+HRMUaux4ouRw64w2uAvIdZEU7HiU+nhi/cnbH4FDOI5fvB/x1S5HUW+dFtGe/be/jwZYqbrwbImIf49NWFN/6FH8MjeJ4XA30uagk9vV4PoiheBj68o+Ct4+DbCHj90Ufjc4ifp6wiCjCbzZbewysj6+yWy3kVm9vb6LR30N0z0W1Z2KmbqNVMWJbF5to/qlaraDabqFQqUFWVMxIg7yNbUHM5ZNhpGUHkEiUJEhNFkuL3tGg9x2rOYQT8C6TB+qHPMDlXAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a modal container with annotation markers.",
              "title": "Image of a modal container with annotation markers.",
              "src": "/static/af5d622df14742870ec748d008c79beb/50383/modal-anatomy.png",
              "srcSet": ["/static/af5d622df14742870ec748d008c79beb/1efb2/modal-anatomy.png 370w", "/static/af5d622df14742870ec748d008c79beb/50383/modal-anatomy.png 740w", "/static/af5d622df14742870ec748d008c79beb/b5a09/modal-anatomy.png 1360w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Card`}</strong>{`: The Card contains content for a modal. It has no stroke or depth applied because it
appears in front of an overlay.`}</li>
        <li parentName="ol"><strong parentName="li">{`Heading (Optional)`}</strong>{`: Heading should display the title of the content or task.`}</li>
        <li parentName="ol"><strong parentName="li">{`Body`}</strong>{`: Modals contain many different types of content in the body. Typical types of content
include media, alerts, dialogs, and/or task-oriented flows.`}</li>
        <li parentName="ol"><strong parentName="li">{`In-line Buttons (Optional)`}</strong>{`: Action should be at the bottom of the container when used. There
are multiple alignments available for use; Left (Default), Center, Full Width & Full Width
Stacked, or Right aligned.`}</li>
        <li parentName="ol"><strong parentName="li">{`Close “X” Icon (Optional)`}</strong>{`: Users must be able to intentionally dismiss a modal. This icon
inherits styling and interactions from our Tertiary Icon-Only Button Variant.`}</li>
        <li parentName="ol"><strong parentName="li">{`Overlay`}</strong>{`: Used to block user interaction with content behind it. When there are no Close “X”
Icon, clicking on the overlay doesn’t dismiss the modal.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Modals allow for entry of data or alert users on any given page after an action has been initiated
and require immediate attention.`}</li>
        <li parentName="ul">{`On web platforms with browser windows wider than 766px, Modals show up in the center of the screen
and in front of an overlay.`}</li>
        <li parentName="ul">{`On web platforms with browser windows less than 767px width, Dialogs show up at the bottom of the
screen and in front of an overlay.`}</li>
        <li parentName="ul">{`In-line buttons used in modal dialogs and non-user input modals, the alignment could be Left
(Default), Center, Full Width & Full Width Stacked, or Right aligned.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Modal to gather immediate input from the user by blocking interaction with the rest of the
page.`}</li>
        <li parentName="ul">{`Use Modal when alert content and text are too large for a standard Toast or Pop-up notification.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider a `}<a parentName="li" {...{
            "href": "/components/popups/dialog"
          }}>{`Dialog`}</a>{` to gather non-critical input from the user without
blocking interaction with the rest of the page.`}</li>
        <li parentName="ul">{`Do not use Modals to serve up easily accessible links or simple messages that can be dismissed
quickly (use `}<a parentName="li" {...{
            "href": "/components/popups/toast"
          }}>{`Toasts`}</a>{` or `}<a parentName="li" {...{
            "href": "/components/popups/popup"
          }}>{`Popups`}</a>{` for this).`}</li>
        <li parentName="ul">{`Do not use Modals to display dense information, such as Tables or Multi-View Containers.`}</li>
        <li parentName="ul">{`Consider a `}<a parentName="li" {...{
            "href": "/components/popups/toast"
          }}>{`Toast`}</a>{` if you are communicating status or confirmation of
the application process to the user.`}</li>
        <li parentName="ul">{`Consider a `}<a parentName="li" {...{
            "href": "/components/popups/menu"
          }}>{`Menu`}</a>{` if the input is a single selection of options.`}</li>
      </ul>
      <h3 {...{
        "id": "responsive-view"
      }}>{`Responsive View`}</h3>
      <p>{`Modal components adjust width and content presentation based on screen size. When content exceeds
the length of the screen, the modal content will become scrollable in the body section of the modal.
For long content on a small screen, inline buttons will continue to scroll with the content.`}</p>
      <h3 {...{
        "id": "touch-based-behavior"
      }}>{`Touch Based Behavior`}</h3>
      <p>{`The overlay on modals are not click or touch enabled to close the modal component view on small
screens between 320-767px. This accounts for accidental touch on mobile devices. Background overlays
will close the modal when clicked on larger devices when the screen reaches the minimum width.`}</p>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-modal-dialogs-impact-the-accessible-experience"
      }}>{`How Modal Dialogs Impact the Accessible Experience`}</h3>
      <p>{`Modal dialog components require special care and attention for delivering an accessible experience.
Modal dialog content is most commonly appended at the bottom of the browser’s DOM, so neglecting to
move focus inside the Modal will likely block keyboard access to the content inside the Modal
dialog.`}</p>
      <p>{`For an accessible listening experience with a screen reader, users must be able to understand the
context of the screen has changed from a full page to a Modal dialog window. This can be
accomplished by using a few well-supported ARIA roles and properties on the Modal dialog container.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`When the Modal dialog appears on screen, keyboard focus must be set to an element inside of the
Modal dialog. If possible, keyboard focus should be returned back to the element that invoked the
modal dialog when it is dismissed.`}</p>
      <p>{`Any interactive elements in the Modal dialog must have a focus indicator that is highly visible
against the background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`Modal dialog must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: Focuses interactive elements included inside the modal dialog (e.g. buttons, links, inputs,
selects, etc.)`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Esc`}</inlineCode>{`: Dismisses the modal dialog`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Modal dialogs must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The context has changed to a “modal dialog” or “web dialog”`}</li>
        <li parentName="ul">{`The title heading of the modal dialog`}</li>
        <li parentName="ul">{`The focused element inside the dialog (e.g. “Close, button”)`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Specify initial focus when the Modal dialog appears`}</li>
        <li parentName="ul">{`Specify keyboard focus after the Modal dialog is dismissed`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`When body content overflows and creates a scrollable area, setting `}<inlineCode parentName="li">{`tabindex=”0”`}</inlineCode>{` may be necessary
to support keyboard scrolling.`}</li>
        <li parentName="ul">{`When setting keyboard focus to a custom target, or when the Close Icon button is not used, the
`}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` property should be set on the Modal container referencing a unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` of the
body text content. This practice supports screen readers when pushing keyboard focus after static
text in the modal.`}</li>
        <li parentName="ul">{`[Included in component]`}{` When Modal dialog appears, set keyboard focus to the first interactive
element inside the Modal dialog.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Keyboard focus must be trapped inside the Modal dialog container.`}</li>
        <li parentName="ul">{`[Included in component]`}{` When Modal dialog is dismissed, return keyboard focus back to the target
element that invoked the Modal dialog.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The ARIA `}<inlineCode parentName="li">{`role=”dialog”`}</inlineCode>{` property must be set on the Modal dialog
container element, and the `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` property must reference the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` of the Modal
dialog title heading.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The `}<inlineCode parentName="li">{`aria-modal=”true”`}</inlineCode>{` property is required for Modal dialogs.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`See the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/dialogs"
          }}>{`Dialogs, Popups, and Modals`}</a>{` page in the UI Text
section of the Content Style Guide for Modal language guidelines.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      